import {
    ref,
    set,
    getDatabase,
    DatabaseReference,
    onValue,
    push,
    remove,
    get,
} from 'firebase/database';
import React, { useEffect, useState } from 'react';
import '../components/instory.css';

const api =
    process.env.NODE_ENV !== 'development'
        ? 'https://asia-southeast1-enda-combine-stock-shopify-app.cloudfunctions.net'
        : 'https://asia-southeast1-enda-combine-stock-shopify-app.cloudfunctions.net';

// const api =
//     process.env.NODE_ENV !== 'development'
//         ? 'https://de7e8a3c97ee.ngrok.app/enda-combine-stock-shopify-app/asia-southeast1'
//         : 'https://de7e8a3c97ee.ngrok.app/enda-combine-stock-shopify-app/asia-southeast1';

interface Story {
    status: string;
    id: string;
    name: string;
    link: string;
    image: string | null;
}

const InstoryComponent = (props: { enableMobile: boolean, enableDesktop: boolean }) => {
    // console.log("props",props)
    const [instoryData, setInstoryData] = useState<Story[]>([]);

    useEffect(() => {
        const fetchInstory = async () => {
            try {
                const response = await fetch(`${api}/getInstory`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (!response.ok) {
                    console.error(`HTTP error! Status: ${response.status}`);
                    throw new Error('Failed to fetch instory data.');
                }

                const data = await response.json();
                if (!data || !Array.isArray(data.storyArray)) {
                    throw new Error('Invalid data structure received.');
                }

                const publishedStories = data.storyArray.filter((story: Story) => story?.status === 'Published');

                setInstoryData(publishedStories);
            } catch (error: any) {
                console.error('Fetch error:', error.message);
            }
        };

        fetchInstory();
    }, [api]);

    // console.log("instoryData", instoryData)

    const handleOpenLink = (link: string) => {
        window.open(link, '_self');
    };

    return (
        <div style={{ display: 'flex', justifyContent: 'center'}}>
        {props.enableDesktop === true && (
            instoryData.map((story, index) => (
                <div key={index} className='desktop' >
                    <div
                        style={{ alignSelf: "center", alignItems: "center", justifyItems: "center", position: "relative", marginTop: "1rem", marginLeft: "0.5rem", marginRight: "0.5rem", cursor: 'pointer', }} >
                        <img
                            src={story.image || ''}
                            alt={`Story ${index + 1}`}
                            style={{ background: "#ffffff", objectFit: "cover", height: "6rem", width: "6rem", borderRadius: "50%", padding: "4px", border: "solid 3px #6f1920", }}
                            onClick={() => handleOpenLink(story.link)}
                        />
                    </div>
                    <p
                        style={{ textAlign: "center", cursor: "pointer" }}
                        onClick={() => handleOpenLink(story.link)}>
                        {story.name}
                    </p>
                </div>
            ))
            )}
             {props.enableMobile === true && (
                <div className='mobile'>
                    {instoryData.map((story, index) => (
                        <div key={index} style={{ marginRight: '0.5rem', marginTop:'1.5rem', marginLeft:'0.5rem'}}>
                            <div
                                style={{ cursor: 'pointer', textAlign:'center' }}
                                onClick={() => handleOpenLink(story.link)}
                            >
                                <img
                                    src={story.image || ''}
                                    alt={`Story ${index + 1}`}
                                    style={{
                                        background: "#ffffff",
                                        objectFit: "cover",
                                        height: "4.5rem",
                                        width: "4.5rem",
                                        borderRadius: "50%",
                                        padding: "4px",
                                        border: "solid 3px #6f1920",
                                    }}
                                />
                            </div>
                            <p
                                style={{ textAlign: "center", cursor: "pointer", width:"5rem" }}
                                onClick={() => handleOpenLink(story.link)}
                            >
                                {story.name}
                            </p>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default InstoryComponent;